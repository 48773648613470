/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
* ======================================================================== */
(function($) {
// Use this variable to set up the common and page specific functions. If you
// rename this variable, you will also need to rename the namespace below.
var Sage = {
// All pages
'common': {
  init: function() {
// JavaScript to be fired on all pages
$('a.share').click(function(e){
e.preventDefault();
var $link   = $(this);
var href    = $link.attr('href');
var network = $link.attr('data-network');

var networks = {
    facebook : { width : 600, height : 300 },
    twitter  : { width : 600, height : 254 },
    linkedin : { width : 600, height : 473 }
};

var popup = function(network){
    var options = 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,';
    window.open(href, '', options+'height='+networks[network].height+',width='+networks[network].width);
};

popup(network);
});
$('#input_2_7').change(function(){
  if (this.value) {
    $('#ginput_quantity_2_15').val(this.value);
    $('#ginput_quantity_2_16').val(this.value);
  } else {
    $('#ginput_quantity_2_15').val(null);
    $('#ginput_quantity_2_16').val(null);
  }
});
function resizeBlankImages() {
  $('img.lazy').each(function () {
    var originalWidth = $(this).attr('data-width');
    var originalHeight = $(this).attr('data-height');
    var ratioH = originalWidth/originalHeight;
    var width = $('.item').width();
    var height = width/ratioH;
    $(this).css("height",height);
    $(this).parent().css("min-height",height);
  });
}
$(window).on("resize",function(e){
  resizeBlankImages();
});
$("div.lazy").lazyload({
  effect : "fadeIn"
});
$("img.lazy").each(function(index) {
  var originalWidth = $(this).attr('data-width');
  var originalHeight = $(this).attr('data-height');
  var ratioH = originalWidth/originalHeight;
  var width = $('.item').width();
  var height = width/ratioH;
  $(this).css("height",height);
  $(this).parent().css("min-height",height);
});
$("img.lazy").lazyload({
  effect : "fadeIn",
  failure_limit: Math.max($("img.lazy").length - 1, 0)
});

    lightbox.option({
      'resizeDuration': 200,
      'wrapAround': true,
      'showImageNumberLabel':false
    });

},
finalize: function() {
// JavaScript to be fired on all pages, after page specific JS is fired
}
},
// Home page
'home': {
  init: function() {
// JavaScript to be fired on the home page

    $(".scrollnav").click(function(e) { 
          // Prevent a page reload when a link is pressed
        e.preventDefault(); 
          // Call the scroll function
                $('html,body').animate({
            scrollTop: $("#navbar").offset().top},
            'slow');          
    });




$('#metatimeline').horizontalTimeline({
  dateDisplay: "year",
  useFontAwesomeIcons: false
});


var owl= $('#owl-first-bloc').owlCarousel({
    nav:true,
  dots:false,
  items:1,
  loop:true,
  video:true,
  lazyLoad: true,
  lazyLoadEager:1,
  autoplay:true,
  autoplayTimeout:3000,
  autoplayHoverPause:true,
  navText:['<i class="fas fa-chevron-circle-left fa-2x text-primary"></i>','<i class="fas fa-chevron-circle-right fa-2x text-primary"></i>']
});
 owl.on('translate.owl.carousel',function(e){
    $('.owl-item video').each(function(){
      $(this).get(0).pause();
      $('.owl-item.active video').addClass('d-none');
      $('.owl-item.active').removeClass('owl-video-playing');
      $('.meta-owl-video-play-icon').removeClass('d-none');
      owl.trigger('play.owl.autoplay',[3000]);
    });
  });

 $('.meta-owl-video-play-icon').click(function(){
  if($('.owl-item.active video').length){
      $(this).addClass('d-none');
      $('.owl-item.active video').removeClass('d-none');
      $('.owl-item.active video').get(0).play();
      $('.owl-item.active').addClass('owl-video-playing');
      owl.trigger('stop.owl.autoplay');
      $(".owl-item.active video").on('ended', function() {
        owl.trigger('play.owl.autoplay',[3000]);
        $('.owl-item.active video').addClass('d-none');
      $('.owl-item.active').removeClass('owl-video-playing');
      $('.meta-owl-video-play-icon').removeClass('d-none');
      });
    }

});


$('#owl-team').owlCarousel({
  loop:true,
  margin:15,
  nav:true,
  responsive:{
    0:{
      items:1
    },
    576:{
      items:2
    },
    768:{
      items:3
    },
    1200:{
      items:4
    }
  },
  navText:['<i class="fas fa-chevron-circle-left fa-2x text-primary"></i>','<i class="fas fa-chevron-circle-right fa-2x text-primary"></i>']
});
var owloptions={
  nav:true,
  dots:true,
  items:1,
  loop:true,
  autoplay:false,
  lazyLoad: true,
  lazyLoadEager:1,
  animateOut: 'fadeOut',
  animateIn: 'fadeIn',
  navText:['<i class="fas fa-chevron-circle-left fa-2x text-primary"></i>','<i class="fas fa-chevron-circle-right fa-2x text-primary"></i>']
};
$('.owl-modal').each(function() {
  var $this = $(this);
  $this.owlCarousel(owloptions);
});
},
finalize: function() {
// JavaScript to be fired on the home page, after the init JS
}
},
'grid_isotope': {
  init:function(){
// partenaires
var $grid_no_filters = $('.grid-no-filters').isotope({
  itemSelector: '.item',
  layout: 'masonry',
  masonry: {
    columnWidth: '.item',
  }
});
$grid_no_filters.on('layoutComplete', function(){
  $(window).trigger("scroll");
});
// réalisations et petites annonces
$(".post-link").click(function(){
  var post_link = $(this).attr("data-url");
  var post_title = $(this).attr("data-title");
  _paq.push(['setDocumentTitle', post_title]);
  _paq.push(['setCustomUrl', post_link]);
  _paq.push(['trackPageView']);
  $("#ajaxmodal").attr("data-permalink", post_link);
});
$(window).on('popstate', function () {
  $("#ajaxmodal").modal('hide');
});
// Create object to store filter for each group
var buttonFilters = {};
var buttonFilter = '*';
// Create new object for the range filters and set default values,
// The default values should correspond to the default values from the slider
var rangeFilters = {
  'width': {'min':7, 'max': 24},
};
// Initialise Isotope
// Set the item selector
var $grid = $('.grid').isotope({
  itemSelector: '.item',
  layout: 'masonry',
  masonry: {
    columnWidth: '.item',
  },
  filter: function() {
    var $this = $(this);
    var width = $this.attr('data-width');
    var isInWidthRange = (rangeFilters.width.min <= width && rangeFilters.width.max >= width);
    return $this.is( buttonFilter ) && (isInWidthRange);
  }
});
$grid.on('layoutComplete', function(){
  $(window).trigger("scroll");
});
var $widthSlider = $('#filter-width').slider({ tooltip_split: true, min: 7,  max: 24, range: true, value: [7, 24] });
function updateRangeSlider(slider, slideEvt) {
  console.log('Current slider:' + slider);
  var sldmin = +slideEvt.value[0],
  sldmax = +slideEvt.value[1],
  filterGroup = slider.attr('data-filter-group'),
  currentSelection = sldmin + ' - ' + sldmax;
  slider.siblings('.filter-label').find('.filter-selection').text(currentSelection);
  console.log('Filtergroup: '+ filterGroup);
  rangeFilters[filterGroup] = {
    min: sldmin || 7,
    max: sldmax || 24
  };
  $grid.isotope();
}
$widthSlider.on('slideStop', function(slideEvt){
  var $this =$(this);
  updateRangeSlider($this, slideEvt);
});
$('.filters-select').on( 'change', function() {
  var $this = $(this);
  var filterValue = this.value;
  var $selectedValue = this;
  var filterGroup = $this.attr('data-filter-group');
  $this.find('.is-checked').removeClass('is-checked');
  $this.find(':selected').addClass('is-checked');
  buttonFilters[ filterGroup ] = filterValue;
  buttonFilter = concatValues( buttonFilters ) || '*';
  console.log( buttonFilter );
  $grid.isotope();
});
function concatValues( obj ) {
  var value = '';
  for ( var prop in obj ) {
    value += obj[ prop ];
  }
  return value;
}
}
},
// About us page, note the change from about-us to about_us.
'contact': {
  init: function() {
// JavaScript to be fired on the about us page
(function( $ ) {
function initMap( $el ) {
var $markers = $el.find('.marker');
var mapArgs = {
  zoom        : $el.data('zoom') || 16,
  mapTypeId   : google.maps.MapTypeId.ROADMAP
};
var map = new google.maps.Map( $el[0], mapArgs );
map.markers = [];
$markers.each(function(){
  initMarker( $(this), map );
});
centerMap( map );
return map;
}
function initMarker( $marker, map ) {
var lat = $marker.data('lat');
var lng = $marker.data('lng');
var latLng = {
  lat: parseFloat( lat ),
  lng: parseFloat( lng )
};
var marker = new google.maps.Marker({
  position : latLng,
  map: map
});
map.markers.push( marker );
if( $marker.html() ){
var infowindow = new google.maps.InfoWindow({
  content: $marker.html()
});
google.maps.event.addListener(marker, 'click', function() {
  infowindow.open( map, marker );
});
}
}
function centerMap( map ) {
var bounds = new google.maps.LatLngBounds();
map.markers.forEach(function( marker ){
  bounds.extend({
    lat: marker.position.lat(),
    lng: marker.position.lng()
  });
});
if( map.markers.length == 1 ){
  map.setCenter( bounds.getCenter() );
} else{
  map.fitBounds( bounds );
}
}
$(document).ready(function(){
  $('.acf-map').each(function(){
    var map = initMap( $(this) );
  });
});
})(jQuery);

}
},

'single': {
  init: function() {
                    var owloptions={
              nav:true,
              margin:10,
              dots:true,
              items:1,
              loop:true,
              autoplay:false,
              autoplayTimeout:6000,
              lazyLoad: true,
              animateOut: 'fadeOut',
              animateIn: 'fadeIn',
               URLhashListener:true,
           startPosition: 'URLHash',
              navText:['<i class="fas fa-chevron-circle-left"></i>','<i class="fas fa-chevron-circle-right"></i>']
            };
   $('.jumbotron .owl-carousel').each(function() {
 //           console.log('ok');
              var $this = $(this);
              $this.owlCarousel(owloptions);
            });
}
},

// About us page, note the change from about-us to about_us.
'meta_yachts_services': {
  init: function() {
// JavaScript to be fired on the about us page
var $grid_no_filters = $('.grid-no-filters').isotope({
  itemSelector: '.item',
  layout: 'masonry',
  masonry: {
    columnWidth: '.item',
  }
});
$grid_no_filters.on('layoutComplete', function(){
  $(window).trigger("scroll");
});
$(".post-link").click(function(){
  var post_link = $(this).attr("data-url");
  var post_title = $(this).attr("data-title");
  _paq.push(['setDocumentTitle', post_title]);
  _paq.push(['setCustomUrl', post_link]);
  _paq.push(['trackPageView']);
  $("#ajaxmodal").attr("data-permalink", post_link);
});
$(window).on('popstate', function () {
  $("#ajaxmodal").modal('hide');
});
(function( $ ) {
function initMap( $el ) {
var $markers = $el.find('.marker');
var mapArgs = {
  zoom        : $el.data('zoom') || 16,
  mapTypeId   : google.maps.MapTypeId.ROADMAP
};
var map = new google.maps.Map( $el[0], mapArgs );
map.markers = [];
$markers.each(function(){
  initMarker( $(this), map );
});
centerMap( map );
return map;
}
function initMarker( $marker, map ) {
var lat = $marker.data('lat');
var lng = $marker.data('lng');
var latLng = {
  lat: parseFloat( lat ),
  lng: parseFloat( lng )
};
var marker = new google.maps.Marker({
  position : latLng,
  map: map
});
map.markers.push( marker );
if( $marker.html() ){
var infowindow = new google.maps.InfoWindow({
  content: $marker.html()
});
google.maps.event.addListener(marker, 'click', function() {
  infowindow.open( map, marker );
});
}
}
function centerMap( map ) {
var bounds = new google.maps.LatLngBounds();
map.markers.forEach(function( marker ){
  bounds.extend({
    lat: marker.position.lat(),
    lng: marker.position.lng()
  });
});
if( map.markers.length == 1 ){
  map.setCenter( bounds.getCenter() );
} else{
  map.fitBounds( bounds );
}
}
$(document).ready(function(){
  $('.acf-map').each(function(){
    var map = initMap( $(this) );
  });
});
})(jQuery);

}
}
};
// The routing fires all common scripts, followed by the page specific scripts.
// Add additional events for more control over timing e.g. a finalize event
var UTIL = {
  fire: function(func, funcname, args) {
    var fire;
    var namespace = Sage;
    funcname = (funcname === undefined) ? 'init' : funcname;
    fire = func !== '';
    fire = fire && namespace[func];
    fire = fire && typeof namespace[func][funcname] === 'function';
    if (fire) {
      namespace[func][funcname](args);
    }
  },
  loadEvents: function() {
// Fire common init JS
UTIL.fire('common');
// Fire page-specific init JS, and then finalize JS
$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
  UTIL.fire(classnm);
  UTIL.fire(classnm, 'finalize');
});
// Fire common finalize JS
UTIL.fire('common', 'finalize');
}
};
// Load Events
$(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.